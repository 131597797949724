import React from 'react'
import { PageTitle, MetaTags } from '@common/components'
import {Paths} from "@common/util";

export const AboutPage = () => {
	return (
		<div className='about-page'>
			<MetaTags
				title='Meist'
				description=''
				link={Paths.ABOUT}
			/>
			<PageTitle h2={true}>Meist</PageTitle>
			<div>
				<h2><b>HEA SÕBER!</b></h2>
				<br />
				<p style={{fontSize: '14px !important'}}>
					Meie iga-aastane jõulukingituste tellimine on avatud!
					<br/><br/>
					Nagu igal aastal, oleme ka seekord kokku pannud hulga uhkeid kingipakke, millega ettevõtte väikeseid sõpru
					rõõmustada.
					<br/><br/>
					Meie arvates võiks pärast toredat jõulupidu lapsele kätte jääda rohkem kui ainult kommipaberid :). Seega, kui
					soovid tavapäraste kommipakkide asemel kinkida lisaks maiustustele ka asjaliku mänguasja, lauamängu või
					raamatu, tasub meiega kindlasti ühendust võtta.
					<br/><br/>
					Meile omaselt oleme pakkidesse poetanud lisaks toredale kingitusele ka midagi magusat. Maiustusi on, nagu
					ikka, väikese kõhu jaoks paras kuhjake. Jätkuvalt saavad nii pisikesed päkapikud kui ka tervislikkust hindavad
					päkapikud oma pakikese neile sobivate maiustustega.
					<br/><br/>
					<span style={{ color: "rgb(166, 28, 50)", fontWeight: "bold" }}>Maia päkapiku pakike</span> sisaldab Kalevi Kirju
					koer kakaobatoonikest (36 gr) ja 200 gr mitut erinevat sorti
					tõeliselt häid komme: Mesikäpa vahvlikomme ja marmelaadikommide valikut, lisaks veel piparkoogibatoonikesi
					ning kõigi laste lemmikuid - Drako närimiskomme.
					<br/><br/>
					<span style={{ color: "rgb(166, 28, 50)", fontWeight: "bold" }}>Tervisliku päkapiku pakike</span> sisaldab Freche
					Freunde mahedat banaani popkorni (15 gr), N.A! Fruit Sticks naturaalseid puuviljasnäkke (35 gr), BeRaw
					puuviljakomme (35 gr), Frupp külmkuivatatud puuviljabatooni (9 gr) ning Yumearth päris mahlast valmistatud
					pulgakommi (6 gr).
					<br/><br/>
					<span style={{ color: "rgb(166, 28, 50)", fontWeight: "bold" }}>Pisikesed päkapikud</span> saavad ka sel aastal
					täitsa oma pakikese neile sobivate maiustustega - pisikesed päkapikud leiavad šokolaadikommide asemel pakist
					Põnni suurepärase ökoloogilise puuviljapüree ja pakikese vahvate maisikrõbuskitega.
					<br/><br/>
					Oleme aastate jooksul märganud, et meie klientidel on palju häid mõtteid ja sellepärast ei piirdu pakivalik
					ainult standardpakkidega, vaid saame kokku panna just sulle sobiva paki.
					<br/><br/>
					Pakkide tellimiseks saada meile e-kiri aadressil <span
					style={{ color: "rgb(166, 28, 50)", fontWeight: "bold" }}>info@joulukontor.ee</span> või helista telefonil <span
					style={{ color: "rgb(166, 28, 50)", fontWeight: "bold" }}>56 939 727</span> ja meie
					usinad päkapikud asuvad tööle!
					<br/><br/><br/>
					Rõõmsal meelel ja heade soovidega
					<br />
					Lelulaeka jõulukontor
					<br/><br/>
					<br/><br/><br/>
					<span style={{ color: "rgb(166, 28, 50)", fontWeight: "bold" }}>Pane tähele!</span>
					<ul>
						<li>Pakkide tellimiseks kirjutage meile aadressil <span
							style={{ color: "rgb(166, 28, 50)", fontWeight: "bold" }}>info@joulukontor.ee</span> soovitud kingituste nimi,
							kogus ja paki tüüp.
						</li>
						<li>Võtame vastu nii ettevõtete kui eraisikute tellimusi.</li>
						<li>Kingipakkide tarne algab 25. november 2024. Kui kingipakke on siiski varem vaja, siis palun kirjutage
							või helistage meile ja püüame lahenduse leida.
						</li>
						<li>Meil ei ole laos valmis kingipakke. Pakime iga kliendi tellimuse eraldi, vastavalt kliendi soovile.
							Seega palume tellimuse tegemisel arvestada minimaalse tarneajaga 7 päeva. Kui kingipakke on siiski
							kiiremini vaja, siis palun kirjutage või helistage ja püüame lahenduse leida.
						</li>
						<li>Erineva tootevalikuga kingituste puhul pakitakse tooted juhuslikkuse alusel. Kui on soov kingipakki
							saada väga konkreetne valiktoode, siis palume see soov kirjutada tellimuse kommentaari. Anname endast
							parima, et neid soove täita.
						</li>
						<li>Kõiki kodulehel esitatud hinnad on ilma käibemaksuta. Hindadele lisandub Eesti Vabariigis kehtestatud
							käibemaks 20%.
						</li>
						<li>Tasumine toimub arve alusel.</li>
						<li>Tellimusele on võimalik ise järele tulla aadressile Mahtra 1, Tallinn (Mustakivi Kaubanduskeskus).</li>
						<li>Soovi korral toimetatakse kingipakid kohale kulleriga. Sellisel juhul lisandub lõpparvele kullerteenuse
							tasu 25 € + km.
						</li>
						<li>Kuni nelja pakiga tellimused saame soovi korral saata ka pakiautomaati. Sellisel juhul lisandub
							lõpparvele kullerteenuse tasu 4 € + km.
						</li>
					</ul>
					<br/>
					<span style={{ fontWeight: "bold" }}>Garantii</span>
					<ul>
						<li>Toote puuduse avastamisel palume sellest teada anda e-posti aadressile info@joulukontor.ee.</li>
						<li>Toote puuduse korral on kliendil õigus nõuda toote asendamist uue tootega või tagastada see müüja kulul.</li>
						<li>Müüja ei vastuta toote puuduste eest, mis on tekkinud toote mittesihtotstarbelise kasutamise, hooldusjuhiste mittejärgmise või selle tavapärasel kasutamisel tekkinud loomuliku kulumise tagajärjel.</li>
					</ul>
				</p>
			</div>
		</div>
	)
}
